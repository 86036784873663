const MYFRIENDS = 'my-friends';
const NOTIFICATIONLIST = 'notification-list';
const FEEDBACK = 'feedback';
const MODULEDETAILS = 'module-details';

export const route = {
  ROOT: "/login",
  DASHBOARD: "/dashboard",
  FORGOTPASSWORD: "/forgot-password",
  ACCOUNTACTIVATION: "/activation",
  ACCOUNTACTIVED: "/account-activated",
  RESETPASSWORD: "/reset-password/:token",
  RESETPASSWORDACTIVED: "/reset-password-activated",
  PROFILE: "/profile",
  CONTACT: "/contact",
  ASSESSMENT: "/assessment",
  ASSESSMENTDETAILS: '/assessment/:id',
  SETTING: "/setting",
  ACCOUNTSETTINGMENU: "/account-setting-menu",
  MYACCOUNT: "/my-account",
  HOME: '/home',
  PROGRESS: '/progress',
  MYCOURSEDETAILS: '/mycourse/:id',
  MYCOURSE: '/mycourse',
  PROGRESSDETAIL: '/progress-details',
  TEACHERS: '/teachers',
  BOOKING: '/booking',
  TEACHERDETAIL: '/teacher',
  COOKIESSETTINGS: '/cookiessettings',
  COURSEDETAIL: '/course',
  IMPERSONATE_STUDENT: '/impersonate-student',
  MYFRIENDS: '/' + MYFRIENDS,
  NOTIFICATIONLIST: '/' + NOTIFICATIONLIST,
  MAINTENANCE: '/503',
  COURSEMAP: '/course-map/:zoneId?',
  COURSEMAPDETAIL: '/course-map/detail/:levelId',
  COURSEMAPLESSONDETAIL: '/course-map/lesson/:contentId',
  PROFILECAPTURE: '/profile-capture/',
  PROFILECAPTUREVALIDATE: '/profile-capture/validate/',
  COURSEMAPPRINT: '/course-map/print/:levelId',
  REDIRECTFEEDBACK: '/'+FEEDBACK+'/:feedbackId',
  COMMUNITYPAGE: '/community',
  ACHIEVEMENTSPAGE: '/achievements',
  HELPPAGE: '/help',
};

export const target = {
  MYFRIENDS: MYFRIENDS,
  FEEDBACK: FEEDBACK,
  NOTIFICATIONLIST: NOTIFICATIONLIST,
  MODULEDETAILS: MODULEDETAILS,
};