import { Button } from "@material-ui/core";
import { memo, useEffect, useRef, useState } from "react";

import Calendar from "../../../components/icon/Calendar";
import MapPin from "../../../components/icon/MapPin";
import User from "../../../components/icon/User";
import Check from "../../../components/icon/Check";
import Key from "../../../components/icon/Key";
import Writing from "../../../components/icon/Writing";
import Repeat from "../../../components/icon/Repeat";
import Warranty from "../../../../assets/image/warranty-icon.png";
import Users from "../../../components/icon/Users";
import Iconnews from "../../../../assets/image/new-icon.png";
import Iconvideo from "../../../../assets/image/video-icon.png";
import AlertCircle from "../../../components/icon/AlertCircle";
import IconLock from "../../../../assets/image/lock-mb.png";
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";


const ModuleViewItemMobile = (props) => {
  const {
    handleOpenDetails,
    item
  } = props;

	
  const {id, title, pathway_color, product_type_color, product_category_color, available_space, product_category_name, product_type_name, start_date, end_date, centre_name, teacher_names, image, progress_check, lesson_times, theme_videos} = item;
  let messageType = ''
  let messageString = ''
  if (available_space <= 0) {
    messageType = 'module-message--red';
    messageString = 'Full';
  }
  if (available_space > 0 && available_space < 4) {
    messageType = 'module-message--orange';
    messageString = `Only ${available_space} space${available_space > 1 ? 's' : ''} left`;
  }
  if (available_space > 3) {
    messageType = 'module-message--default';
    messageString = `${available_space} Spaces left`;
  }
  
  const { isMobile } = useCheckMobileTablet();
  const cateColor = item?.theme_object?.left_font_color;
  const typeColor = item?.theme_object?.right_font_color;
  const newIcon = item?.theme_object?.is_new;
  const [showVideo, setShowVideo] = useState(false);

  const handleOpenVideo = () => {
    setShowVideo(true)
  }
  const handleCloseVideo = () => {
    setShowVideo(false)

    if (scriptRef.current) {
      window.viddlerAutoEmbedsRun = false;
      scriptRef.current.remove();
    }
  }
  const scriptRef = useRef(null);

  useEffect(() => {
    if (showVideo) {
        const script = document.createElement("script");
        script.setAttribute("src", 'https://static.cdn-ec.viddler.com/js/arpeggio/v3/build/main-built.js');

        document.body.appendChild(script);
        scriptRef.current = script;
    }

    return (() => {
      if (scriptRef.current) {
        window.viddlerAutoEmbedsRun = false;
        scriptRef.current.remove();
      }
    });
  }, [showVideo]);
  return (
    <div className="module-view-item">
      <div className="module-view-item__img" style={{borderColor: pathway_color ? pathway_color : '#94D070'}}>
        <img src={image} alt={title} />
        {newIcon == 1 ? <div className='module-view-item__iconnew'>
         <img className="icon-new icon-new-mb" src={Iconnews} alt="New Icon" />
        </div>  : "" }
        {item?.theme_videos?.length > 0 ?  (
          <div className="module-view-video">
            <button  variant="contained" className="btn btn-video" style={{backgroundColor: product_category_color ? product_category_color : '#94D070', color: cateColor}} onClick={() => handleOpenVideo()}>
              <span className="icon-video"><img src={Iconvideo} alt="Video" /></span>What you will learn!
            </button>
          </div>) : ""}
      </div>
      <div className="module-view-item__info">
        <ul className="module-view-item__wrap">
          <li>
            <span className="cate" style={{backgroundColor: product_category_color ? product_category_color : '#94D070', color: cateColor}}>
              {product_category_name}
            </span>
          </li>
          <li>
            <span className="cate" style={{backgroundColor: product_type_color ? product_type_color : '#97DAFF', color: typeColor}}>
              {product_type_name}
            </span>
          </li>
        </ul>
        <div className="module-view__right">
          <div>
            <h3 className="module-view-item__title">
              {title}
            </h3>
            <ul className="module-view-item__list">
              <li className="module-view__date">
                <Calendar className="top-block__icon" />
                {start_date} - {end_date}  
              </li>
              <li className='module-view__location'>
                <MapPin className="top-block__icon" />
                {centre_name}
              </li>
              {Object.keys(teacher_names).length > 0 &&
                <li className='module-view__location'>
                  {Object.keys(teacher_names).length === 1 ? <User className="top-block__icon" /> : <Users className="top-block__icon" />}
                  {Object.keys(teacher_names).length === 1 ? teacher_names[Object.keys(teacher_names)[0]] : <span className='txt-details'>See <b>‘Details’</b> for teachers</span>}
                </li>
              }
            </ul>
          </div>
        </div>
        <div className='plan-item__list'>
          <div className='plan-item__wrap'>
            {lesson_times.map((item, index) => {
              return (
                <div className="plan-item" key={item.id}>
                  <div className="plan-item__number">
                    <div className={`plan-item__number-value
                        ${item.is_absent ? 'missed' : ''}
                        ${item.is_unbooked_missed ? 'unbooked-missed' : ''} 
                        ${item.is_delivered ? 'completed' : ''} 
                        ${item.is_next_class ? 'unlocked' : ''}
                      `}
                    >
                      {index + 1}
                      {!item.is_absent && item.is_learned_lesson && (
                        <div className="sub-icon checked">
                          <Check />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
            {progress_check &&
              <>
                <div className="plan-item">
                  <div className="plan-item__number">
                    <ProgressCheck progressCheckState={progress_check.progress_state} />
                  </div>
                </div>
                <div className="plan-item">
                  <div className="plan-item__number">
                    <BadgeAwarded progressCheckState={progress_check.progress_state} />
                  </div>
                </div>
              </> 
            }
          </div>
        </div>
        <div className="wrap-button">
          <Button variant="contained" className={`btn btn-detail`} onClick={() => handleOpenDetails(id)}>Details</Button>
          <p className={`module-message ${messageType}`}>
            <Users />
            {messageString}
          </p>
        </div>
      </div>
      {theme_videos.length > 0 && (
        <div className={`module-view-item__video ${showVideo ? 'show' : ''}`}>
          <div className="module-view-item__video__wrapper">
            <button onClick={handleCloseVideo} className="btn-mob-close">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.00391 1.00342L0.99697 7.00342L7.00391 13.0034" stroke="#23085A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                Back
            </button>
            <div className="content-block-video">
              {showVideo && theme_videos && theme_videos.map((item, index)=> (
                <>
                {item.button_flag == true ?
                <div key={index} className={`block-video ${(item.unlock_before == 24 ? 'block-unlocked' : "")} ${item.unlock_before == 24 && item.is_unlocked == false ? 'lock-video' : ''}`}>
                  <h3 className="block-title">{item.title}</h3>
                  <p className="txt-description">{item.description}</p>
                  <div className="block-video__main">
                    <span class="loader"></span>
                    <iframe id="kaltura_player" data-height={isMobile ? "160" : "261"} className="viddler-auto-embed" src={item.url} allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay ; fullscreen ; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" title="BCE_B2_GR_Wish_and_If_only"></iframe>
                  </div>
                  {(item.unlock_before == 24 && item.is_unlocked == false) ?
                    <div className="show-alert">
                      <div className="show-alert__content">
                      <img className="icon-lock-mb" src={IconLock} alt="Lock" />
                        <p>
                          This video will become available<br/>
                          24 hours before the module start.
                        </p>
                      </div>
                    </div>
                  : ""}
                </div> : ""}
              </>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const ProgressCheck = ({progressCheckState}) => {
  switch (progressCheckState) {
    case 0:
      return (<div className="plan-item__number-value">
          <Writing className="plan-item__number-icon" />
          <div className="sub-icon">
            <Key />
          </div>
        </div>);
    case 1:
      return (<div className="plan-item__number-value unlocked">
          <Writing className="plan-item__number-icon" />
          <div className="sub-icon checked">
            <Repeat />
          </div>
        </div>);
    case 2:
      return (<div className="plan-item__number-value completed">
          <Writing className="plan-item__number-icon" />
          <div className="sub-icon checked">
            <Check />
          </div>
        </div>);
  
    default:
      return (<div className="plan-item__number-value">
        <Writing className="plan-item__number-icon" />
        <div className="sub-icon">
          <Key />
        </div>
      </div>)
  }
}
const BadgeAwarded = ({progressCheckState}) => {
  switch (progressCheckState) {
    case 0:
      return (<div className="plan-item__number-value">
          <img className="img-bnr" src={Warranty} alt="" />
          <div className="sub-icon">
            <Key />
          </div>
        </div>);
    case 2:
      return (<div className="plan-item__number-value completed">
          <img className="img-bnr" src={Warranty} alt="" />
          <div className="sub-icon checked">
            <Check />
          </div>
        </div>);
    
  
    default:
      return (<div className="plan-item__number-value">
          <img className="img-bnr" src={Warranty} alt="" />
          <div className="sub-icon">
            <Key />
          </div>
        </div>);
  }
}
export default memo(ModuleViewItemMobile);