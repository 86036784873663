import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const LandingRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!rest.user) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }

      if (rest.user.credentials && rest.user.credentials.expires_in) {
        const redirectTo = localStorage.getItem("redirectTo")
        
        if(redirectTo!=null){
          const fromLocation = JSON.parse(redirectTo)
          return <Redirect to={{ pathname: fromLocation.pathname, search: fromLocation.search, state: { from: props.location } }} />;
        }
        return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />;
      }
    }}
  />
);
export default LandingRoute;