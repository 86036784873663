import { memo, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import PracticeListLoadingMobile from "./PracticeListLoadingMobile";
import ProgressViewItemMobile from "./ProgressViewItemMobile";
import useProgress from "../../../../hooks/HomePage/useProgress";
import Modal from "../../Dialog/Modal"
import { refreshUrl } from '../../../../components/Utilities';
import { useSocket } from "../../../../hooks/HomePage/useSocket";
const ProgressListViewMobile = (props) => {
  const [message, setMessage] = useState("");
  
  const {
    isLoading,
    isLoadingMoreInProgress,
    isLoadingMoreCompleted,
    progressList,
    loadMore,
    showLoadMoreButtonCompleted,
    showLoadMoreButtonInProgress,
    COMPLETED,
    INPROGRESS,
    getData
  } = useProgress();
  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState(false);
  const [progressUrl, setProgressUrl] = useState();
  const [progressModalMessage, setProgressModalMessage] = useState('');
  
  const handleCloseProgressModal = () => {
    setMessage("");
    setIsOpenProgressDetail(false);
  }
  const handleOpenProgressModal = (item) => {
    if(item.modal_message != undefined){
      setProgressModalMessage(item.modal_message);
    }else{
      setProgressModalMessage("");
    }
    setProgressUrl(refreshUrl(item.button_url));
    setIsOpenProgressDetail(true);
    
  };
  
  const handleOpenReviewModal = (item)=>{
    setProgressModalMessage("");
    setProgressUrl(item.review_activity_url);
    setIsOpenProgressDetail(true);
  }

  useEffect(() => {
    if (isOpenProgressDetail == false) {
      getData();
    }
  }, [isOpenProgressDetail]);

  useSocket({
    callBack: (payload) => {
      // reloadData()
      getData()
      if(payload.message){
        setProgressModalMessage("");
      }
      setMessage(payload.message);
    }
  });
  return (
    <>
      {isLoading ? (
        <div className="PracticeViewItemMobile booked-classes">
          <div className="booked-classes__section">
            <div className="--title">
              <Skeleton
                animation="wave"
                variant="text"
                className="skeleton-heading"
              />
            </div>
            <div className="--list-section">
              <PracticeListLoadingMobile></PracticeListLoadingMobile>
            </div>
          </div>
        </div>
      ) : (
        <>
        <div className="PracticeViewItemMobile ProgressViewItemMobile booked-classes PracticeViewItemMobile--progress">
          <h3 className="section_title">Achieve a score of 70% or higher to earn your badge and certificate for a theme</h3>
          <div className="booked-classes__section">
            <div className="--title">
              In progress ({progressList?.inProgress?.total ?? 0})
            </div>
            {(progressList?.inProgress?.themes?.length === 0 || !progressList?.inProgress) ? (
              <p className="message-not-found">You have no Progress checks available yet. </p>
            ) : ''}
            {progressList?.inProgress?.themes?.length > 0 && (
              <div className="--list-section">
                {progressList?.inProgress &&
                  progressList?.inProgress?.themes?.map((item, index) => (
                    <ProgressViewItemMobile
                      key={`${index}${item.lesson_id}`}
                      handleOpenProgressModal={handleOpenProgressModal}
                      handleOpenReviewModal={handleOpenReviewModal}
                      buttonTitle={item.button_text}
                      title={item.product_type_name}
                      subTitle={item.theme_name}
                      grade={item.grade}
                      color={item.color}
                      classes={''}
                      desc={item.hint}
                      url={item.button_url}
                      item={item}
                    />
                  ))}
                {isLoadingMoreInProgress && (
                  <PracticeListLoadingMobile></PracticeListLoadingMobile>
                )}
              </div>
            )}
          </div>

          <div className="btn-show-more-container">
            {showLoadMoreButtonInProgress && (
              <>
                {!isLoadingMoreInProgress ? (
                  <Button
                    variant="contained"
                    onClick={() => loadMore(INPROGRESS)}
                    className="btn btn-show-more"
                  >
                    Load more
                  </Button>
                ) : (
                  ""
                )}
              </>
            )}
          </div>

          <div className="booked-classes__section">
            <div className="--title">
              Completed ({progressList?.completed?.total ?? 0})
            </div>
            {(progressList?.completed?.themes?.length === 0 || !progressList?.completed) ? (
              <p className="message-not-found">You have not completed any Progress checks, with a score of 70% or higher, yet.</p>
            ) : ''}
            {progressList?.completed?.themes?.length > 0 && (
              <div className="--list-section">
                {progressList?.completed &&
                  progressList?.completed?.themes?.map((item, index) => (
                    <ProgressViewItemMobile
                      key={`${index}${item.lesson_id}`}
                      handleOpenProgressModal={handleOpenProgressModal}
                      handleOpenReviewModal={handleOpenReviewModal}
                      buttonTitle={item.button_text}
                      title={item.product_type_name}
                      subTitle={item.theme_name}
                      grade={item.grade}
                      color={item.color}
                      classes={''}
                      desc={item.hint}
                      url={item.review_activity_url}
                      credentialPage={item.button_url}
                      item={item}
                    />
                  ))}
                {isLoadingMoreCompleted && (
                  <PracticeListLoadingMobile></PracticeListLoadingMobile>
                )}
              </div>
            )}
          </div>

          <div className="btn-show-more-container">
            {showLoadMoreButtonCompleted && (
              <>
                {!isLoadingMoreCompleted ? (
                  <Button
                    variant="contained"
                    onClick={() => loadMore(COMPLETED)}
                    className="btn btn-show-more"
                  >
                    Load more
                  </Button>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          
        </div>
        <Modal
          open={isOpenProgressDetail}
          handleCloseDialog={handleCloseProgressModal}
        >
          <Button variant="contained" onClick={handleCloseProgressModal} className="btn btn-return btn-top">Close</Button>
          <div className="external-box">
          {message
            ? <p className='test-new'>{message ? message : ''}</p>
            : <div className='test-new'>{progressModalMessage} </div>
          }
            <iframe src={progressUrl} width="100%" height="100%" title="Progress"/>
          </div>
        </Modal>
        </>
      )}
    </>
  );
};

export default memo(ProgressListViewMobile);
