import React, { useState, useEffect } from 'react';
import '../../../../sass/private/MyCourse/CourseItem.scss';
import { Button } from '@material-ui/core';
import { refreshUrl } from '../../../components/Utilities';
import keyIcon from '../../../../assets/image/key.png';
// import CircularProgressBar from '../../Common/CircularProgressBar';

import Modal from '../Dialog/Modal';
import CircularProgressBar from '../Common/CircularProgressBar';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSocket } from "../../../hooks/HomePage/useSocket";
import useProgress from '../../../hooks/HomePage/useProgress';
import useMyCourseDetails from '../../../hooks/MyCoursePage/useMyCourseDetails';

const CourseItem = (props) => {
  const {handleCloseDialog, themeProgress, disable, themeProgresslist, themeProgressTitle, themeProgressDesc, max_grade, color } = props;
  const [open, setOpen] = useState(false);
  const lessonRequired = themeProgress?.lesson_required ? parseInt(themeProgress.lesson_required) : 0;
  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState();
  const [progressUrl, setProgressUrl] = useState("");
  const history = useHistory();
  const [progressModalMessage, setProgressModalMessage] = useState('');

  const borderColor =() => {
    if(themeProgress?.is_locked){
      return '#898A8D'
    }
    if(!themeProgress?.is_locked && !themeProgress?.is_finished) {
      return '#23085A'
    }
    if(!themeProgress?.is_locked && themeProgress?.is_finished) {
      return '#00953B'
    }
  }
  const handleClickOpen = (url) => {
    window.open(url, "_blank");
  };
  const handleCloseProgressModal = () => {
    setMessage("");
    setIsOpenProgressDetail(false);
    handleCloseDialog();
  };
  const handleOpenProgressModal = (url) => {
    if(url.modal_message != undefined){
      setProgressModalMessage(url.modal_message);
    }else{
      setProgressModalMessage("");
    }
    setProgressUrl(refreshUrl(url));
    setIsOpenProgressDetail(true);
    // history.push(getRoute('HOME'));
  };

  const onClose = () => {
    history.goBack();
  };
  const [message, setMessage] = useState("");
  const {
    progressList,
  } = useProgress();
  const {
    getData
  } = useMyCourseDetails();

  //update socket
  // useEffect(() => {
  //   if (isOpenProgressDetail == false) {
  //     onSideBarClick();
  //   }
  // }, [isOpenProgressDetail]);
  useEffect(() => {
    if (isOpenProgressDetail == false) {
      getData();
    }
  }, [isOpenProgressDetail]);
  
  useSocket({
    callBack: (payload) => {
      // reloadData()
      getData()
      // alert(payload.message);
      if(payload.message){
        setProgressModalMessage("");
      }
      setMessage(payload.message);
    }
  });
  return (
    <>
    {
      themeProgress && Object.keys(themeProgress)?.length > 0 &&  
      <>
        <div className="course-item__title course-title">Theme progress check</div>
        <div className="course-item__desc course-desc">Attend all {lessonRequired} lesson{lessonRequired > 1 ? 's': ''} in this theme to unlock the progress check.</div>
        <div className="course-desc">Achieve a score of 70% or higher to earn your badge and certificate for this theme.</div>
        <div className={`course-item course-item-list ${disable ? 'disable' : ''}`} style={{'borderLeftColor': color ? color : '#009D8C'}}>
          <div>
            <div className="course-item__desc desc-course">{themeProgresslist}</div>
            <div className="course-item__title">{themeProgressTitle}</div>
            <div className="text textDesc">{themeProgressDesc}</div>
          </div>
      
          <div className="course-item__right">
            {
              themeProgress?.is_locked === 1 && <img src={keyIcon} alt="Content locked"/>
            }

            {
              themeProgress?.is_locked === 0 && themeProgress?.is_finished === 0 &&
              <Button className="btn MuiButton-contained btn-default" onClick= {() => handleOpenProgressModal(themeProgress?.lti_activity_link)}>
                Start
              </Button>
            }

            {
              themeProgress?.is_locked === 0 && themeProgress?.is_finished === 1 &&
              <div className="has-score">
                <div className="icon-block secondary information-progress grade">
                  <CircularProgressBar
                    strokeWidth="3"
                    sqSize="52"
                    completeProgress={themeProgress.max_grade}
                    percentage={100 - (themeProgress.max_grade*10)}
                  />
                </div>
                {/* <div className="assessment-score grade">{themeProgress.max_grade}%</div> */}
                {/* <Button className="btn MuiButton-contained btn-default btn-margin" onClick={() => handleClickOpen(themeProgress?.lti_activity_link)}>
                  {themeProgress?.button_text}
                </Button> */}
                {themeProgress?.open_in_new_page  ?
                  <>
                  <div className="view-right">
                      <Button className="btn MuiButton-contained btn-default btn-margin" onClick={() => handleClickOpen(themeProgress?.lti_activity_link)}>
                        {themeProgress?.button_text}
                      </Button>
                      {themeProgress?.button_text === "Share" ?
                          <span className='btn-link' onClick={() => handleOpenProgressModal(themeProgress?.review_activity_url)}>Review activities</span>
                          : ""
                      }
                  </div>
                  </>
                  : (
                    <>
                      <Button className="btn MuiButton-contained btn-default btn-margin" onClick={() => handleOpenProgressModal(themeProgress?.lti_activity_link)} >{themeProgress?.button_text}</Button>
                    </>
                  )
                }
                
              </div>
            }
          </div>
        </div>
      </>
    }

    <Modal
      open={open}
      handleCloseDialog={handleCloseDialog}
    >
      <Button variant="contained" onClick={handleCloseDialog} className="btn btn-return btn-top">Close</Button>
      <div className="external-box">
        <iframe src={themeProgress?.lti_activity_link} width="100%" height="100%"/>
      </div>
    </Modal>
    <Modal
        open={isOpenProgressDetail}
        handleCloseDialog={handleCloseDialog}
      >
        <Button
          variant="contained"
          onClick={handleCloseProgressModal}
          className="btn btn-return btn-top"
        >
          Close
        </Button>
        <div className="external-box">
          {message
            ? <p className='test-new'>{message ? message : ''}</p>
            : <div>
                <div className='test-new'>{themeProgress?.modal_message}</div>
            </div>
          }
          <iframe
            src={progressUrl}
            width="100%"
            height="100%"
            title="Progress"
          />
        </div>
      </Modal>
    </>
  )
}

export default CourseItem;